import { ArrowButton } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { Container } from "@/Layout/Container"
import { useIntl } from "react-intl"
import "./style.css"

export const Help = () => {
  const intl = useIntl()
  return (
    <section className="home_section help">
      <Container>
        <h2 className="h2 help__h2">
          <Msg id="buy5" />
        </h2>
        <div className="help__row">
          <div className="help__cell">
            <h3 className="help__title">
              <Msg id="buy6" />
            </h3>
            <p className="help__describe">
              <Msg id="sup1" />
            </p>
            <ArrowButton title={intl.formatMessage({ id: "need_help" })} to="/contact-us" />
          </div>
          <div className="help__cell">
            <h3 className="help__title">
              <Msg id="sup4" />
            </h3>
            <p className="help__describe">
              <Msg id="sup3" />
            </p>
            <ArrowButton title={intl.formatMessage({ id: "go" })} to="/blog" />
          </div>
          <div className="help__cell">
            <h3 className="help__title">
              <Msg id="faq" />
            </h3>
            <p className="help__describe">
              <Msg id="sup5" />
            </p>
            <ArrowButton title={intl.formatMessage({ id: "need_help" })} to="/faq" />
          </div>
        </div>
      </Container>
    </section>
  )
}
